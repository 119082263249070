
import { defineComponent } from "vue";
import {
  getRotated,
  getCube,
  faceNames,
  FaceName,
} from "@/utils/projects/rubiksCube/cube";
import {
  getStepsToSolve,
  getDistanceToSolved,
  Params,
} from "@/utils/projects/rubiksCube/solvers/index";

const algorythmOptions: Params["algorythm"][] = ["brute-force", "dijkstra"];
const storageOptions: Params["storage"][] = ["object", "array"];

function factorial(n: number): number {
  if (n <= 1) {
    return n;
  }

  return n * factorial(n - 1);
}

const totalCombinations = factorial(9 * 6);

function parseTime(millis: number) {
  const seconds = millis / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const years = days / 365;

  let result = "";

  if (years >= 1) {
    result += years.toFixed().padStart(4, "0") + "years ";
  }

  if (days >= 1) {
    result += (days % 365).toFixed().padStart(2, "0") + "days ";
  }

  if (hours >= 1) {
    result += (hours % 24).toFixed().padStart(2, "0") + "h ";
  }

  if (minutes >= 1) {
    result += (minutes % 60).toFixed().padStart(2, "0") + "m ";
  }

  result += (seconds % 60).toFixed(2).padStart(5, "0") + "s";

  return result;
}

export default defineComponent({
  data() {
    return {
      faceNames,
      algorythmOptions,
      storageOptions,
      totalCombinations,
      params: {
        algorythm: "dijkstra" as Params["algorythm"],
        storage: "object" as Params["storage"],
        cube: getCube(),
      },
      stepsToSolve: [] as FaceName[],
      isSolving: false,
      shouldStopSolver: false,
      stats: {
        total: 0,
        discarded: 0,
        cube: getCube(),
        depth: 0,
        positionsPerSecond: 0,
        distance: 0,
        elapsedTime: 0,
      },
    };
  },

  computed: {
    currentDistanceToSolved(): number {
      return getDistanceToSolved(this.params.cube);
    },

    elapsedTimeParsed(): string {
      return parseTime(this.stats.elapsedTime);
    },

    elapsedTimeUntilSolvedParsed(): string {
      const seconds =
        (totalCombinations - this.stats.total) / this.stats.positionsPerSecond;

      const millis = seconds * 1000;

      return parseTime(millis);
    },
  },

  methods: {
    rotate(faceName: FaceName) {
      this.params.cube = getRotated(this.params.cube, faceName);
    },

    solve() {
      if (this.isSolving) {
        return;
      }

      (async () => {
        this.isSolving = true;

        this.shouldStopSolver = false;

        this.stepsToSolve = [];

        this.stepsToSolve = await getStepsToSolve({
          cube: this.params.cube,
          progressCallback: (stats) => {
            this.stats = { ...stats };

            return {
              shouldStop: this.shouldStopSolver,
            };
          },
          algorythm: this.params.algorythm,
          storage: this.params.storage,
        });

        this.isSolving = false;
      })();
    },

    stopSolving() {
      this.shouldStopSolver = true;
    },
  },
});
