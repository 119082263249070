
import { defineComponent } from "vue";
import Title from "@/components/projects/Title.vue";
// import Canvas from "@/components/projects/rubiksCube/Canvas.vue";
import Map from "@/components/projects/rubiksCube/Map.vue";

export default defineComponent({
  components: {
    Title,
    // Canvas,
    Map,
  },
  data() {
    return {
      title: "Rubiks Cube Solver",
    };
  },
});
