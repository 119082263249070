import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-446e7e56"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "cube cube-sm" }
const _hoisted_4 = ["face"]
const _hoisted_5 = ["face"]
const _hoisted_6 = { style: {"display":"flex","justify-content":"center","align-items":"center","height":"80vh"} }
const _hoisted_7 = { class: "cube" }
const _hoisted_8 = ["face", "onClick"]
const _hoisted_9 = ["face"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("p", null, [
        _createElementVNode("label", null, [
          _createTextVNode(" Algorythm: "),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.algorythm) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.algorythmOptions, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option,
                value: option
              }, _toDisplayString(option), 9, _hoisted_1))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.params.algorythm]
          ])
        ])
      ]),
      _createElementVNode("p", null, [
        _createElementVNode("label", null, [
          _createTextVNode(" Storage structure: "),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.storage) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storageOptions, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option,
                value: option
              }, _toDisplayString(option), 9, _hoisted_2))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.params.storage]
          ])
        ])
      ]),
      _createElementVNode("p", null, [
        (_ctx.isSolving)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("button", {
                style: {"width":"200px","height":"60px","background":"red","border-radius":"10px","cursor":"pointer"},
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.stopSolving()))
              }, " STOP "),
              _createTextVNode(" SOLVING... ")
            ], 64))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              style: {"width":"200px","height":"60px","background":"green","border-radius":"10px","cursor":"pointer"},
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.solve()))
            }, " SOLVE "))
      ]),
      _createElementVNode("p", null, "Processed positions: " + _toDisplayString(_ctx.stats.total), 1),
      _createElementVNode("p", null, "Discarded positions: " + _toDisplayString(_ctx.stats.discarded), 1),
      _createElementVNode("p", null, " Discard percentage: " + _toDisplayString(((_ctx.stats.discarded * 100) / _ctx.stats.total).toFixed()) + "% ", 1),
      _createElementVNode("p", null, " Positions processed per second: " + _toDisplayString(_ctx.stats.positionsPerSecond.toFixed(2)), 1),
      _createElementVNode("p", null, " Total posible combinations: " + _toDisplayString(_ctx.totalCombinations), 1),
      _createElementVNode("p", null, " Percentage processed: " + _toDisplayString(((_ctx.stats.total * 100) / _ctx.totalCombinations).toFixed(70)) + " % ", 1),
      _createElementVNode("p", null, " Estimated time until solved: " + _toDisplayString(_ctx.elapsedTimeUntilSolvedParsed), 1),
      _createElementVNode("p", null, "Movemet depth: " + _toDisplayString(_ctx.stats.depth), 1),
      _createElementVNode("p", null, "Distance to solved: " + _toDisplayString(_ctx.stats.distance), 1),
      _createElementVNode("p", null, "Elapsed time: " + _toDisplayString(_ctx.elapsedTimeParsed), 1),
      _createElementVNode("p", null, "Current distance to solved: " + _toDisplayString(_ctx.currentDistanceToSolved), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faceNames, (faceName) => {
          return (_openBlock(), _createElementBlock("div", {
            key: faceName,
            class: "face",
            face: faceName
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (_, row) => {
              return _createElementVNode("div", {
                key: row,
                class: "row"
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (_, column) => {
                  return _createElementVNode("div", {
                    key: column,
                    class: "cell",
                    face: _ctx.stats.cube[faceName][row][column].faceName
                  }, null, 8, _hoisted_5)
                }), 64))
              ])
            }), 64))
          ], 8, _hoisted_4))
        }), 128))
      ]),
      _createElementVNode("p", null, "Steps to solve: " + _toDisplayString(_ctx.stepsToSolve), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faceNames, (faceName) => {
          return (_openBlock(), _createElementBlock("div", {
            key: faceName,
            class: "face",
            face: faceName,
            onClick: ($event: any) => (_ctx.rotate(faceName))
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (_, row) => {
              return _createElementVNode("div", {
                key: row,
                class: "row"
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (_, column) => {
                  return _createElementVNode("div", {
                    key: column,
                    class: "cell",
                    face: _ctx.params.cube[faceName][row][column].faceName
                  }, null, 8, _hoisted_9)
                }), 64))
              ])
            }), 64))
          ], 8, _hoisted_8))
        }), 128))
      ])
    ])
  ], 64))
}